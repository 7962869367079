import Link from "@/src/common/components/Link"
import classNames from "classnames"
import { ReactNode } from "react"
import tinycolor from "tinycolor2"

import Typography from "../Typography"

export type TableHeaderMeta = {
  imageUrl?: string | null
}

export type TableHeaderV2<T, S extends TableHeaderMeta = TableHeaderMeta> = {
  name: string
  keepIfEmpty?: boolean
  id: keyof T
  key: keyof T
  backgroundColor?: string
  meta?: S
}

export type TablePropsV2<T extends { href?: string }> = {
  headers: Array<TableHeaderV2<T>>
  rows: Array<T>
  idField: keyof T
  className?: string
  onClickRow?: (row: T) => void
  headerCell?: (
    name: TableHeaderV2<T>["name"],
    row: TableHeaderV2<T>
  ) => ReactNode
  bodyCell?: (value: T[keyof T], key: keyof T, row: T) => ReactNode
  thClass?: string
}

function removeEmptyColumnHeaders<T>(
  headers: Array<TableHeaderV2<T>>,
  rows: Array<T>
) {
  return headers.filter(
    ({ id, keepIfEmpty = true }) =>
      keepIfEmpty ||
      !rows.every((row) => [undefined, null, ""].includes(row[id] as any)) // not empty column
  )
}

export default function TableV2<T extends { href?: string }>({
  headers,
  rows,
  onClickRow,
  className: extraClassNames = "",
  headerCell,
  bodyCell,
  thClass
}: TablePropsV2<T>) {
  const filteredHeaders = removeEmptyColumnHeaders(headers, rows)
  const leftMostClassNames =
    "first:sticky first:left-0 first:border-r first:border-gray-200 max-w-[160px]"
  return (
    <div
      className={`border border-gray-200 overflow-scroll ${extraClassNames}`}
    >
      <table className="table-auto relative border-separate border-spacing-0 w-full">
        <thead>
          <tr>
            {filteredHeaders.map((row, idx) => {
              const { name, backgroundColor } = row

              return (
                <th
                  className={classNames(
                    `p-0 sticky top-0 z-10 first:z-20 border-b border-gray-200`,
                    {
                      [thClass as string]: thClass,
                      "bg-gray-100": !thClass,
                      "text-gray-800": !backgroundColor,
                      "text-white": backgroundColor
                    },
                    leftMostClassNames
                  )}
                  style={
                    backgroundColor
                      ? {
                          backgroundColor: backgroundColor
                        }
                      : {}
                  }
                  key={name}
                >
                  <Typography
                    type="p15x"
                    weight="medium"
                    className={classNames(
                      `px-2 py-2 md:py-4 whitespace-nowrap text-inherit font-inherit`,
                      {
                        "text-left": idx === 0
                      }
                    )}
                  >
                    {headerCell?.(name, row) ?? name}
                  </Typography>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {rows.map((row: T, rowIdx) => (
            <tr key={rowIdx}>
              {filteredHeaders.map(({ name, key, backgroundColor }, idx) => {
                const value = row[key]
                const cell =
                  idx === 0 ? (
                    <Typography
                      type="p15"
                      className="px-2 py-2 whitespace-nowrap text-gray-600 text-sm w-full overflow-x-scroll"
                    >
                      {bodyCell?.(value, key, row) ?? value}
                    </Typography>
                  ) : typeof value === "string" ? (
                    <p className="px-2 py-2 whitespace-nowrap text-gray-600 text-center text-sm">
                      {bodyCell?.(value, key, row) ?? value}
                    </p>
                  ) : (
                    <div className="flex justify-center">
                      {bodyCell?.(value, key, row) ?? value}
                    </div>
                  )

                return (
                  <td
                    key={name}
                    className={classNames(
                      "p-0 min-h-[52px] first:z-10",
                      leftMostClassNames,
                      {
                        "bg-white": !backgroundColor
                      }
                    )}
                    style={
                      backgroundColor
                        ? {
                            backgroundColor: tinycolor(backgroundColor)
                              .setAlpha(0.2)
                              .toRgbString()
                          }
                        : {}
                    }
                  >
                    {row.href ? (
                      <Link legacyBehavior href={row.href || "#"}>
                        <a onClick={() => onClickRow?.(row)}>{cell}</a>
                      </Link>
                    ) : (
                      cell
                    )}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
