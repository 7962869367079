import { ELECTION_PARTY_COLOR_OTHERS } from "@/src/elections/business-layer/constants"
import classNames from "classnames"

export const EMPTY_COLOR = "#ABACAE"

export type Bar = {
  ratio: number
  color?: string | null
}

type HorizontalStackProps = {
  bars: Array<Bar>
}

const HorizontalStack = ({ bars }: HorizontalStackProps) => (
  <ul className="h-2 md:h-4 flex flex-row w-full">
    {bars.map(({ color, ratio }, idx) => (
      <li
        key={"bar-" + idx}
        data-testid={"bar-" + idx}
        className={classNames(
          ":first-child:ml-0 ml-[1px] md:ml-[2px] block h-full w-full",
          {
            "bg-gray-600": idx >= 2 || idx === bars.length - 1,
            "bg-green-600": idx === 1 && idx !== bars.length - 1,
            "bg-blue-400": idx === 0 && idx !== bars.length - 1
          }
        )}
        style={{
          width: ratio * 100 + "%",
          backgroundColor: color ?? ELECTION_PARTY_COLOR_OTHERS
        }}
      />
    ))}
  </ul>
)

export default HorizontalStack
