import StearsLogoCandidate from "@/public/assets/img/stears-logo-candidate.png"
import Link from "@/src/common/components/Link"
import { formatNumber, getPercentage } from "@/src/common/utils"
import { CandidateCampaignInfo } from "@/src/elections/business-layer/types"
import classNames from "classnames"
import Image from "next/legacy/image"

import Avatar from "../Avatar"
import Icon from "../Icon"
import Typography from "../Typography"
import HorizontalStack, { EMPTY_COLOR } from "./HorizontalStack"
import PartyLabel from "./PartyLabel"

export type CandidateCampaignInfoWithVotes = {
  votes: number
  totalVotes: number
} & Omit<CandidateCampaignInfo, "votes" | "totalVotes">

export const CANDIDATE_OUTCOME_MOCK_DATA: CandidateCampaignInfoWithVotes = {
  constituency: "",
  constituency_code: "",
  href: "",
  race: "",
  slug: "",
  year: "",
  // logo: "https://stears-elections.s3.eu-west-1.amazonaws.com/static/APC.png",
  name: "Lawal Dauda",
  party: "APC",
  partyColor: "#60A5FA",
  partyLogoURL:
    "https://stears-elections.s3.eu-west-1.amazonaws.com/static/APC.png",
  photoURL:
    "https://stears-elections.s3.eu-west-1.amazonaws.com/static/candidate-photos/governor/Zamfara/PDP_Zamfara_Lawal+Dauda_256.png",
  votes: 255505,
  totalVotes: 3545050,
  stateMinimum: true,
  winner: true
}

/**
 * Max width recommened for wrapper - max-w-lg or max-w-md
 */
const Candidate = ({
  name,
  party,
  partyColor,
  partyLogoURL,
  photoURL,
  votes,
  totalVotes,
  stateMinimum,
  href,
  onClickCandidate
}: {
  onClickCandidate: () => void
} & CandidateCampaignInfoWithVotes) => {
  let ratio = votes / totalVotes
  ratio = !isNaN(ratio) ? ratio : 0

  return (
    <article className="-md:border border-gray-10">
      <section className="p-4 md:p-5 flex flex-row justify-between">
        <span className="flex flex-row align-top">
          <Link legacyBehavior href={href}>
            <a onClick={onClickCandidate} className="mr-3">
              <Avatar size="md" src={photoURL} />
            </a>
          </Link>

          <article className="flex flex-col justify-between">
            <Link legacyBehavior href={href}>
              <a onClick={onClickCandidate}>
                <Typography
                  type="p15x"
                  weight="medium"
                  className="text-gray-800"
                  style={{ color: partyColor }}
                >
                  {name}
                </Typography>
              </a>
            </Link>
            <div className="mt-1">
              <PartyLabel
                name={party}
                logoURL={partyLogoURL}
                responsive={false}
              />
            </div>
          </article>
        </span>

        <div className="ml-auto -mt-4 -mr-2">
          <Image
            src={StearsLogoCandidate}
            alt="Stears"
            width={32}
            height={9}
            className="font-bold text-gray-600"
          />
        </div>
        {/*{winner ? (
          <div className="px-3 h-5 md:h-7 bg-green-50 rounded-full flex flex-col justify-around">
            <Typography type="p12x" className="mx-0 my-auto text-green-800">
              Winner
            </Typography>
          </div>
        ) : null}*/}
      </section>
      <section className="p-4 md:p-5 flex flex-row md:bg-gray-100">
        <section className="flex-1">
          <div className="flex flex-row justify-start">
            <Typography type="p12x" className="text-gray-800">
              {formatNumber(votes)} votes
            </Typography>
            <Typography type="p12x" className="text-gray-600 ml-1">
              {getPercentage(votes, totalVotes, true)}
            </Typography>
          </div>
          <div className="mt-2 flex flex-row">
            <HorizontalStack
              bars={[
                {
                  color: partyColor,
                  ratio: ratio
                },
                {
                  color: EMPTY_COLOR,
                  ratio: 1 - ratio
                }
              ]}
            />
          </div>
        </section>

        {stateMinimum ? (
          <section className="ml-auto mr-0 flex-1 text-right">
            <div className="inline-flex flex-col justify-center items-center">
              <Typography type="p12x" className="text-gray-800">
                State minimum?
              </Typography>
              <div
                className={classNames(
                  "w-8 mt-2 px-3 rounded-full h-2 md:h-5 flex flex-col justify-around",
                  stateMinimum ? "bg-green-50" : "bg-red-100"
                )}
              >
                {stateMinimum ? (
                  <Icon size="xs3" icon="tick2" />
                ) : (
                  <Icon size="xs3" icon="x3" />
                )}
              </div>
            </div>
          </section>
        ) : null}
      </section>
    </article>
  )
}
export default Candidate
