import { PartyDominancePartyData } from "@/src/common/types"
import { formatNumber, getPercentage } from "@/src/common/utils"

import Typography from "../Typography"
import HorizontalStack, { EMPTY_COLOR } from "./HorizontalStack"
import PartyLabel from "./PartyLabel"

export const VOTES_PER_PARTY_MOCK_DATA: VotesPerPartyProps = {
  parties: [
    {
      name: "APC",
      code: "APC",
      race: "president",
      votes: 109365,
      totalVotes: 120000,
      color: "#60A5FA",
      seatsWon: 3,
      logoURL:
        "https://stears-elections.s3.eu-west-1.amazonaws.com/static/APC.png",
      votePercentage: 20.0
    },
    {
      name: "PDP",
      code: "PDP",
      race: "president",
      votes: 46000,
      totalVotes: 120000,
      color: "#46AE4E",
      seatsWon: 3,
      logoURL:
        "https://stears-elections.s3.eu-west-1.amazonaws.com/static/PDP.png",
      votePercentage: 20.0
    },
    {
      name: "LP",
      code: "LP",
      race: "president",
      votes: 20365,
      totalVotes: 120000,
      color: "#DC2626",
      seatsWon: 3,
      logoURL:
        "https://stears-elections.s3.eu-west-1.amazonaws.com/static/LP.png",
      votePercentage: 23.0
    },
    {
      name: "Others",
      code: "OTHERS",
      race: "president",
      votes: 8034,
      totalVotes: 120000,
      color: "black",
      seatsWon: 3,
      votePercentage: 25.0
    }
  ]
}

type VotesPerPartyProps = {
  parties: Array<PartyDominancePartyData>
  title?: string
}

const VotesPerParty = ({
  parties,
  title = "Votes Per Party"
}: VotesPerPartyProps) => (
  <article className="p-4 md:p-5 border border-gray-10">
    {title && (
      <>
        <Typography type="p12x" className="text-gray-800 mb-4 md:hidden">
          {title}
        </Typography>
        <Typography type="p15x" className="text-gray-800 mb-6 -md:hidden">
          {title}
        </Typography>
      </>
    )}
    <ul className="mt-5">
      {parties.map(({ color, votes, logoURL, name, totalVotes }) => {
        let ratio = votes / totalVotes
        ratio = !isNaN(ratio) ? ratio : 0

        return (
          <li key={name} className="mt-5">
            <section className="flex flex-row justify-between">
              <PartyLabel name={name} logoURL={logoURL} />
              <div className="flex flex-row justify-start">
                <Typography
                  type="p12x"
                  className="text-gray-800"
                >{`${formatNumber(votes)} votes`}</Typography>
                <Typography type="p12x" className="text-gray-600 ml-1">
                  {getPercentage(votes, totalVotes, true)}
                </Typography>
              </div>
            </section>
            <div className="mt-4">
              <HorizontalStack
                bars={[
                  { color, ratio },
                  { color: EMPTY_COLOR, ratio: 1 - ratio }
                ]}
              />
            </div>
          </li>
        )
      })}
    </ul>
  </article>
)
export default VotesPerParty
