import { PartyDominancePartyData } from "@/src/common/types"
import { ELECTION_PARTY_COLOR_OTHERS } from "@/src/elections/business-layer/constants"
import classNames from "classnames"

import Typography from "../Typography"
import HorizontalStack from "./HorizontalStack"

export const PARTY_DOMINANCE_CARD_MOCK_DATA: PartyDominanceProps = {
  title: "Governorship ish",
  parties: [
    {
      name: "APC",
      code: "APC",
      race: "governor",
      votes: 109365,
      totalVotes: 180000,
      color: "#60A5FA",
      seatsWon: 3,
      logoURL:
        "https://stears-elections.s3.eu-west-1.amazonaws.com/static/APC.png",
      votePercentage: 20.0
    },
    {
      name: "PDP",
      code: "PDP",
      race: "governor",
      votes: 46000,
      totalVotes: 180000,
      color: "#46AE4E",
      seatsWon: 3,
      logoURL:
        "https://stears-elections.s3.eu-west-1.amazonaws.com/static/PDP.png",
      votePercentage: 20.0
    },
    {
      name: "LP",
      code: "LP",
      race: "governor",
      votes: 20365,
      totalVotes: 180000,
      color: "#DC2626",
      seatsWon: 3,
      logoURL:
        "https://stears-elections.s3.eu-west-1.amazonaws.com/static/LP.png",
      votePercentage: 23.0
    },
    {
      name: "Others",
      code: "OTHERS",
      race: "governor",
      votes: 8034,
      totalVotes: 180000,
      color: "#4B5563",
      seatsWon: 3,
      votePercentage: 25.0
    }
  ]
}

type PartyDominanceProps = {
  title: string
  parties: Array<PartyDominancePartyData>
}

const PartyDominance = ({ title, parties }: PartyDominanceProps) => {
  const totalSeat = parties.reduce((acc, { seatsWon }) => acc + seatsWon, 0)

  const removePartyWithZeroDominance = ({
    seatsWon
  }: PartyDominancePartyData) => seatsWon > 0

  const sortPartyBySeatsWon = (
    partyA: PartyDominancePartyData,
    partyB: PartyDominancePartyData
  ) => partyB.seatsWon - partyA.seatsWon

  const filteredParties = parties
    .filter(removePartyWithZeroDominance)
    .sort(sortPartyBySeatsWon)

  return (
    <article className="p-4 md:p-5 border border-gray-10">
      {title && (
        <>
          <Typography type="p12x" className="text-gray-800 mb-4 md:hidden">
            {title}
          </Typography>
          <Typography type="p15x" className="text-gray-800 mb-6 -md:hidden">
            {title}
          </Typography>
        </>
      )}
      <header className="flex flex-wrap mb-6">
        {filteredParties.map(({ name, color }, idx) => (
          <div
            className={classNames("inline-flex items-center mb-1", {
              "mr-4 md:mr-8": idx < filteredParties.length - 1
            })}
            key={name}
          >
            <span
              className="inline-block mr-1 w-5 h-5"
              style={{
                backgroundColor:
                  name === "Others"
                    ? ELECTION_PARTY_COLOR_OTHERS
                    : color ?? undefined
              }}
            />

            <span className="inline-block font-light text-[15px] font-jakarta">
              {name}
            </span>
          </div>
        ))}
      </header>
      <ul className="flex flex-row">
        {filteredParties.map(({ name, seatsWon }, idx) => (
          <li
            key={name}
            style={{
              display: "flex",
              flexBasis: (seatsWon / totalSeat) * 100 + "%",
              flexShrink: idx // the leftmost index (0) should never shrink.
              // If you want the 2nd to shrink less, add exponential (e.g. idx * idx)
            }}
          >
            <div>
              <Typography type="p12x" className="text-gray-600 mt-2 md:hidden">
                {seatsWon}
              </Typography>
              <Typography type="p15x" className="text-gray-600 mt-2 -md:hidden">
                {seatsWon}
              </Typography>
            </div>
          </li>
        ))}
      </ul>
      <div className="mt-4 md:mt-5">
        <HorizontalStack
          bars={filteredParties.map(({ seatsWon, color }) => ({
            color: color,
            ratio: seatsWon / totalSeat
          }))}
        />
      </div>
    </article>
  )
}
export default PartyDominance
