import classNames from "classnames"
import Image from "next/legacy/image"

import Typography from "../Typography"

const PartyLabel = ({
  logoURL,
  name,
  children,
  responsive = true
}: {
  name: string
  responsive?: boolean
  logoURL?: string | null
  children?: any
}) => (
  <article className="flex flex-row align-top">
    {logoURL && (
      <div
        className={classNames("mr-2 h-4 w-4 block", {
          "md:h-5 md:w-5": responsive
        })}
      >
        <Image
          src={logoURL}
          alt={name}
          width={20}
          height={20}
          layout="responsive"
          className={classNames("h-4 w-4", {
            "md:h-5 md:w-5": responsive
          })}
        />
      </div>
    )}
    <div>
      <Typography
        type="p15x"
        className={classNames("text-gray-600", {
          "md:hidden": responsive
        })}
      >
        {name}
      </Typography>
      {responsive ? (
        <Typography type="p18x" className="text-gray-600 -md:hidden">
          {name}
        </Typography>
      ) : null}
      {children}
    </div>
  </article>
)

export default PartyLabel
