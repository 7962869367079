import Candidate from "./Candidate"
import HeadToHead from "./HeadToHead"
import PartyDominance from "./PartyDominance"
import VotesPerParty from "./VotesPerParty"

const ResultsCard = () => <></>
ResultsCard.Candidate = Candidate
ResultsCard.HeadToHead = HeadToHead
ResultsCard.PartyDominance = PartyDominance
ResultsCard.VotesPerParty = VotesPerParty

export default ResultsCard
