import Link from "@/src/common/components/Link"
import { formatNumber, getPercentage } from "@/src/common/utils"
import { CandidateCampaignInfoWithVotes } from "@/src/elections/components/ResultsCard/Candidate"
import classNames from "classnames"

import Avatar from "../Avatar"
import Icon from "../Icon"
import Notice from "../Notice"
import Typography from "../Typography"
import HorizontalStack from "./HorizontalStack"
import PartyLabel from "./PartyLabel"

export const HEAD_TO_HEAD_CARD_MOCK_DATA: HeadToHeadProps = {
  candidates: [
    {
      name: "Bello Mohammed Matawalle Maradun",
      slug: "",
      race: "",
      year: "",
      href: "",
      constituency: "",
      constituency_code: "",
      winner: true,
      photoURL:
        "https://stears-elections.s3.eu-west-1.amazonaws.com/static/candidate-photos/governor/Zamfara/APC_Zamfara_Bello+Mohammed+Matawalle+Maradun_256.png",
      partyLogoURL:
        "https://stears-elections.s3.eu-west-1.amazonaws.com/static/APC.png",
      party: "APC",
      partyColor: "#60A5FA",
      votes: 255505,
      totalVotes: 4000302
    },
    {
      name: "Lawal Dauda",
      slug: "",
      race: "",
      year: "",
      href: "",
      constituency: "",
      constituency_code: "",
      winner: false,
      photoURL:
        "https://stears-elections.s3.eu-west-1.amazonaws.com/static/candidate-photos/governor/Zamfara/PDP_Zamfara_Lawal+Dauda_256.png",
      partyLogoURL:
        "https://stears-elections.s3.eu-west-1.amazonaws.com/static/PDP.png",
      party: "PDP",
      partyColor: "#46AE4E",
      votes: 200673,
      totalVotes: 4000302
    }
  ]
}

type HeadToHeadProps = {
  candidates: Array<CandidateCampaignInfoWithVotes>
  notice?: string
  hideVotePercentage?: boolean
}

const HeadToHead = ({
  candidates,
  notice,
  onClickCandidate,
  hideVotePercentage = false
}: {
  onClickCandidate: (candidate: CandidateCampaignInfoWithVotes) => void
} & HeadToHeadProps) => {
  const totalVotesWon = candidates.reduce(
    (acc, { votes }: CandidateCampaignInfoWithVotes) => acc + (votes ?? 0),
    0
  )

  const computedTotal = candidates[0]?.totalVotes ?? totalVotesWon

  const EMPTY = {
    name: "empty",
    partyColor: "#ABACAE",
    votes: computedTotal - totalVotesWon
  }

  const candidatesWithEmpty = [candidates[0], EMPTY, candidates[1]].filter(
    (candidate) => !!candidate
  )

  return (
    <article className="p-4 md:p-5 border border-gray-10">
      <section className="mt-2 flex flex-row justify-between">
        {candidates.map(
          (
            { name, photoURL, partyLogoURL, href, party, partyColor, ...rest },
            idx
          ) => {
            const isSecondCandidate = idx === 1

            return (
              <article
                key={name + "-profile"}
                className={classNames("flex flex-col", {
                  "items-start": idx === 0,
                  "items-end": isSecondCandidate
                })}
              >
                <Link legacyBehavior href={href}>
                  <a
                    onClick={() =>
                      onClickCandidate({
                        name,
                        href,
                        party,
                        photoURL,
                        partyLogoURL,
                        ...rest
                      })
                    }
                    className={classNames("flex flex-col", {
                      "items-end": isSecondCandidate
                    })}
                  >
                    <Avatar size="md" src={photoURL} />
                    <Typography
                      type="p15x"
                      weight="medium"
                      className="mt-4 text-gray-800"
                      style={{ color: partyColor }}
                    >
                      {name}
                    </Typography>
                  </a>
                </Link>
                <div className="mt-2">
                  <PartyLabel name={party} logoURL={partyLogoURL} />
                </div>
              </article>
            )
          }
        )}
      </section>
      <section className="flex flex-row justify-between mt-8">
        {candidates.map(({ name, winner, votes }) => (
          <article key={name + "-votes"}>
            <div className="flex flex-row justify-start">
              <Typography
                type="p12x"
                className="text-gray-800"
              >{`${formatNumber(votes)} votes`}</Typography>
              {winner ? <Icon size="xs" icon="tick3" className="ml-1" /> : null}
            </div>
            {!hideVotePercentage ? (
              <Typography
                type="p12x"
                className="text-gray-600 mt-1"
              >{`(${getPercentage(votes, computedTotal)}%)`}</Typography>
            ) : null}
          </article>
        ))}
      </section>
      <section className="mt-4">
        <HorizontalStack
          bars={candidatesWithEmpty.map(({ votes, partyColor }) => {
            let ratio = votes / computedTotal
            ratio = !isNaN(ratio) ? ratio : 0

            return {
              ratio: ratio,
              color: partyColor
            }
          })}
        />
      </section>

      {notice ? (
        <Notice className="mt-8 mb-2 rounded-lg align-top">{notice}</Notice>
      ) : null}
    </article>
  )
}

export default HeadToHead
